import "./App.css";
import { Amplify } from "aws-amplify";
import { Authenticator, useTheme, Heading, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import Uploader from "./Uploader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

Amplify.configure(awsExports);

const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

const components = {
  VerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
  },

  ConfirmVerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
  },
};

export default function App() {
  return (
    <Authenticator variation="modal" 
      formFields={formFields}
      components={components}
      hideSignUp={true}>
    {({ signOut, user }) => (
      <div className="App">
      <Button onClick={signOut}>Sign out</Button>
      <Container fluid="sm">
        <Row>
          <Col sm={3}>
            <Image
              fluid
              className="mt-5 logo"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Olympic_rings_without_rims.svg/400px-Olympic_rings_without_rims.svg.png"
              alt="Logo"
            />
          </Col>
          <Col sm={8}>
            <Container className="mt-5" fluid>
              <h1
                align="left"
                style={{
                  color: "black",
                }}
                className="title"
              >
                Data Feed Dashboard
              </h1>
              <p
                align="left"
                style={{
                  color: "black",
                }}
                className="subtitle"
              >
                Uploading a file would validate it against the schema defined in
                the <b>Partners Data Feed Integration</b> document.{" "}
              </p>
              <p
                align="left"
                style={{
                  color: "black",
                }}
                className="subtitle"
              >
                Once the file has been validated, then you will be able to
                deliver it to DDnA.{" "}
              </p>
            </Container>
          </Col>
        </Row>
      </Container>
      <Uploader />
      </div>
    )}
    </Authenticator>
  );
}