import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

import axios from "axios";


const Uploader = () => {
  // Data states
  const [j, updateJ] = useState([]);
  const [downloadUrl, updatedownloadUrl] = useState("");
  const [isUpdatePreview, setIsUpdatePreview] = useState(false);
  // Button states
  const [clearEnabled, updateClearEnabled] = useState(false);
  const [downloadReportEnabled, updateDownloadReportEnabled] = useState(false);
  const [uploadFileEnabled, updateUploadFileEnabled] = useState(false);
  // Graphic elements states
  const [spinnerRunning, updateSpinnerRunning] = useState(false);
  const [isError, updateIsError] = useState(false);
  const [isSubmitted, updateIsSubmitted] = useState(false);
  // Final Upload
  const [finalUploadJson, updateFinalUploadJson] = useState({});
  const [isWrongFormat, updateIsWrongFormat] = useState(false);
  const [fileIsValid, updateFileIsValid] = useState(false);

  const columns = [
    {
      dataField: "line_index",
      text: "Line Index",
    },
    {
      dataField: "rule",
      text: "Rule Name",
    },
    {
      dataField: "message",
      text: "Message",
    },
    {
      dataField: "value",
      text: "Value",
    },
    {
      dataField: "field",
      text: "Field",
    },
  ];

  const SIGN_BASE_URL = process.env.REACT_APP_SIGN_BASE_URL;
  const UPLOAD_URL = process.env.REACT_APP_UPLOAD_BASE_URL;
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

  const headers = { "Content-Type": "application/json" };

  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(status, meta);
    if (status === "rejected_file_type") {
      updateIsWrongFormat(true);
    }
    setIsUpdatePreview(!isUpdatePreview);
  };

  const handleSubmit = async (files, allFiles) => {
    try {
      const f = files[0];

      const unique_uuid = uuidv4();
      const file_ext = f["file"]["name"].split(".").pop();

      updateSpinnerRunning(true);

      var file_name = unique_uuid + "." + file_ext;

      // * GET The presigned url to first upload the file
      const response = await axios.post(
        SIGN_BASE_URL,
        {
          bucket_name: BUCKET_NAME,
          method: "put_object",
          object_key: "inputs/" + file_name,
        },
        { headers: headers }
      );
      const presigned_url = response["data"]["presigned_url"];

      // * Build the body for final uploading of the file *
      var final_file_name = f["file"]["name"];
      var ready_file_name = file_name;
      if (!["gpg", "pgp"].includes(file_ext)) {
        final_file_name = final_file_name + ".gpg";
        ready_file_name = ready_file_name + ".gpg";
      }
      const final_upload_body = {
        input_key: "ready/" + ready_file_name,
        output_key: "file-validator/validator-lambda/" + final_file_name,
      };
      updateFinalUploadJson(final_upload_body);

      // * PUT request: upload file to S3
      await axios.put(presigned_url, f["file"], {
        headers: { "Content-Type": "text/csv" },
      });

      // * now POLLING for getting the result
      const OUT_FILE_NAME = file_name + ".json";

      async function subscribe(url, retries = 0) {
        let response = await fetch(url);

        if (retries >= parseInt(process.env.REACT_APP_MAX_RETRIES)) {
          let message = "too many retries: " + retries;
          console.log(message);
          updateSpinnerRunning(false);
        } else if (response.status === 502) {
          await subscribe(url, retries + 1);
        } else if (response.status !== 200) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          await subscribe(url, retries + 1);
        } else {
          let message = await response.text();
          const parsed = JSON.parse(message).map((obj, index) => {
            return { ...obj, i: index };
          });

          updateJ(parsed);

          updatedownloadUrl(url);
          updateSpinnerRunning(false);

          updateClearEnabled(true);
          if (parsed.length === 0) {
            updateUploadFileEnabled(true);
            updateFileIsValid(true);
          }
          updateDownloadReportEnabled(true);
          updateIsSubmitted(false);
        }
      }

      await axios
        .post(
          SIGN_BASE_URL,
          {
            bucket_name: BUCKET_NAME,
            method: "get_object",
            object_key: "outputs/" + OUT_FILE_NAME,
          },
          { headers: headers }
        )
        .then((response) => {
          subscribe(response["data"]["presigned_url"]);
        });
    } catch (err) {
      console.log(err);
      updateSpinnerRunning(false);
      updateIsError(true);
      updateClearEnabled(true);
      updateUploadFileEnabled(false);
      updateDownloadReportEnabled(false);
      updateIsSubmitted(false);
    }
  };

  const handleClearClick = () => {
    updateJ([]);
    updateClearEnabled(false);
    updateUploadFileEnabled(false);
    updateDownloadReportEnabled(false);
    updateIsSubmitted(false);
  };

  const handleUploadClick = () => {
    updateSpinnerRunning(true);

    axios
      .post(UPLOAD_URL, finalUploadJson, { headers: headers })
      .then((resp) => {
        updateSpinnerRunning(false);
        updateIsSubmitted(true);
        updateUploadFileEnabled(false);
      })
      .catch((err) => {
        console.log(err);
        updateSpinnerRunning(false);
        updateIsError(true);
        updateClearEnabled(true);
        updateUploadFileEnabled(false);
        updateDownloadReportEnabled(false);
        updateIsSubmitted(false);
      });
  };

  return (
    <div className="Uploader">
      <Container fluid="sm" className="mt-5">
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <Container className="mt-5">
              <Dropzone
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                hjello
                maxFiles={1}
                accept=".csv,.gpg,.pgp"
                multiple={false}
                canCancel={false}
                inputContent="Drop A File"
                styles={{
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Container>
          </Col>
          <Col sm={2}></Col>

          <Row>
            <Col sm={3}></Col>

            <Col sm={2} className="mt-5">
              <Row>
                <Container className="mt-2">
                  <Button
                    variant="primary"
                    disabled={!clearEnabled}
                    onClick={handleClearClick}
                  >
                    Clear
                  </Button>
                </Container>
              </Row>
            </Col>
            <Col sm={2} className="mt-5">
              <Row>
                <Container className="mt-2">
                  {!downloadReportEnabled ? (
                    <Button
                      variant="primary"
                      disabled={true}
                      //href={downloadUrl}
                    >
                      Download Report
                    </Button>
                  ) : (
                    <Button variant="primary" href={downloadUrl}>
                      Download Report
                    </Button>
                  )}
                </Container>
              </Row>
            </Col>
            <Col sm={2} className="mt-5">
              <Row>
                <Container className="mt-2">
                  <Button
                    variant="primary"
                    disabled={!uploadFileEnabled}
                    onClick={handleUploadClick}
                  >
                    Upload
                  </Button>
                </Container>
              </Row>
            </Col>
            <Col sm={3}></Col>
          </Row>
        </Row>

        <Row>
          <Col sm={12}>
            {isSubmitted && (
              <Container className="mt-5">
                <Alert
                  variant="success"
                  onClose={() => updateIsSubmitted(false)}
                  dismissible
                >
                  <Alert.Heading>File Successfully uploaded!</Alert.Heading>
                  <p>
                    The file was submitted with success. It will be available in DDnA systems.
                  </p>
                </Alert>
              </Container>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {isWrongFormat && (
              <Container className="mt-5">
                <Alert
                  variant="danger"
                  onClose={() => updateIsWrongFormat(false)}
                  dismissible
                >
                  <Alert.Heading>Cannot upload file.</Alert.Heading>
                  <p>
                    The submitted file must be have one of the following
                    extensions: csv, pgp, gpg.
                  </p>
                </Alert>
              </Container>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="mt-5">
            {spinnerRunning ? (
              <Spinner />
            ) : (
              <>
                {isError ? (
                  <Container className="mt-5">
                    <Alert
                      variant="danger"
                      onClose={() => updateIsError(false)}
                      dismissible
                    >
                      <Alert.Heading>
                        An error occurred during the submission of the file.
                      </Alert.Heading>
                      <p>Please contact DDnA to receive assistance.</p>
                    </Alert>
                  </Container>
                ) : (
                  <>
                    {fileIsValid ? (
                      <Container className="mt-5">
                        <Alert
                          variant="success"
                          onClose={() => updateFileIsValid(false)}
                          dismissible
                        >
                          <Alert.Heading>
                            Submitted file is Valid!
                          </Alert.Heading>
                          <p>
                            The file you submitted is a valid file and can be
                            uploaded to DDnA with the "Upload" button.
                          </p>
                        </Alert>
                      </Container>
                    ) : (
                      <Container className="mt-5">
                        <BootstrapTable
                          keyField="i"
                          data={j}
                          columns={columns}
                          pagination={paginationFactory()}
                        />
                      </Container>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

<Uploader />;

export default Uploader;
